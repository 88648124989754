import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moeda'
})
export class MoedaPipe implements PipeTransform {

  transform(value: string | number): string {
    if(value && value != null){
     const numValue: number = Number(value);
     return  numValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }else{
      return "R$ -";
    }
  }

}
