export class Prefeitura {
    prefeituraId: string;
    razaoSocial: string;
    nomeFantasia: string;
    inscricaoMunicipal: string; 
    cnpj: string;
    cep: string;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    telefone: string;
    email: string;
    ativo: boolean;
}