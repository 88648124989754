import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseDate'
})
export class ParseDatePipe implements PipeTransform {

  transform(value: string, format: 'br' | 'us' = 'br'): { year: number, month: number, day: number } | null {
    if (!value || typeof value !== 'string') return null;

    try {
      if (format === 'br') {
        // Formato BR: dd/MM/yyyy
        const [day, month, year] = value.split('/').map(Number);
        if (this.isValidDate(year, month, day)) {
          return { year, month, day };
        }
      } else if (format === 'us') {
        // Formato US: yyyy-MM-dd
        const [year, month, day] = value.split('-').map(Number);
        if (this.isValidDate(year, month, day)) {
          return { year, month, day };
        }
      }
    } catch (e) {
      // Tratamento de erro opcional
      console.error('Invalid date format:', e);
    }

    return null; // Retorna null se a data for inválida ou o formato não for reconhecido
  }

  private isValidDate(year: number, month: number, day: number): boolean {
    if (!year || !month || !day) return false;
    const date = new Date(year, month - 1, day); // Meses em JS vão de 0 a 11
    return date.getFullYear() === year &&
           date.getMonth() === month - 1 &&
           date.getDate() === day;
  }
}