import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import packageJson from '../../../../../../package.json';



@Component({
  selector: 'app-footer-login',
  templateUrl: './footer-login.component.html',
  styleUrls: ['./footer-login.component.scss']
})
export class FooterLoginComponent implements OnInit {
  windowScrolled: boolean;
  topOffset: number = 150; // Top offset to display scroll to top button
  public currentUser: User;
  public version = (packageJson as any).version;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _authenticationService: AuthenticationService,
  ){
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      window.pageYOffset > this.topOffset ||
      document.documentElement.scrollTop > this.topOffset ||
      document.body.scrollTop > this.topOffset
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }
}
