import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: { year: number, month: number, day: number }, format: 'br' | 'us' = 'br'): string {
    if (!value || !value.year || !value.month || !value.day) return null;

    const { year, month, day } = value;

    // Formata dia e mês com 2 dígitos
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    // Formata conforme o padrão solicitado
    if (format === 'br') {
      return `${formattedDay}/${formattedMonth}/${year}`;
    } else if (format === 'us') {
      return `${year}-${formattedMonth}-${formattedDay}`;
    } else {
      return ''; // Retorna vazio se o formato não for reconhecido
    }
  }
}