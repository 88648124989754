import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.error.redirect && [401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          this._router.navigate(['/pages/miscellaneous/not-authorized']);

          // ? Can also logout and reload if needed
          this._authenticationService.logout();
          //location.reload(true);
        }
        // throwError
        if(err.error.messages){

        }
        // const error = err.error.message || (err.ok?err.statusText: "Erro inesperado, aguarde estamos trabalhando para solucionar");
        const error = Array.isArray(err.error.messages)
            ? (err.error.messages.length === 1
                ? err.error.messages[0]
                : err.error.messages.map(message => `* ${message}`).join('<br>'))
            : err.error.message || (err.ok ? err.statusText : "Erro inesperado, aguarde estamos trabalhando para solucionar");

        return throwError(error);
      })
    );
  }
}
