export const locale = {
  lang: 'de',
  data: {
    MENU: {
      VENDA: 'Venda',
      CADASTRO:{
        COLLAPSIBLE: 'Cadastro',
        GUIA: 'Guia/Condutor',
        MOTORISTA: 'Motorista/Piloto',
        VEICULO: 'Veículos'
      },
      ADMINISTRACAO: {
        COLLAPSIBLE: 'Administração',
      },
      DASHBOARD: {
        COLLAPSIBLE: 'Atalhos',
        BADGE: '2',
        ANALYTICS: 'Graficos',
        prevenda: 'Vendas'
      },
      APPS: {
        SECTION: 'Menu',
        EMAIL: 'Email',
        CHAT: 'Chat',
        TODO: 'Todo',
        CALENDAR: 'Calendar',
        INVOICE: {
          COLLAPSIBLE: 'Financeiro',
          LIST: 'Vendas de Passeio',
          PREVIEW: 'Gerar DAM',
          EDIT: 'Realizar Recarga',
          ADD: 'Movimentação Crédito',
          RECARGA: 'Recarga Agencia',
          RECOLH: 'Recolhimento de Taxa'
        },
        ECOMMERCE: {
          COLLAPSIBLE: 'Gerenciamento',
          SHOP: 'Check Point',
          DETAIL: 'Passeios',
          WISHLIST: 'Autorização de Trânsito',
          CHECKOUT: 'Solicitações de Veículos',
          EMITIRNFSE: 'Emitir NFSE',
          NFSEEMITIDAS: 'NFSE Emitidas',
          PORTALTRANSP: 'Portal da Transparencia',
          EMBARC: 'Embarcações',
          MOVHOSP: 'Movimentação de Hospedagem',
          APROVA: 'Aprovação',
          HORARIOS: 'Horarios',
          RESERVAS: 'Reservas'
        },
        RELATORIOS: {
          COLLAPSIBLE: 'Relatorios',
          SHOP: 'Reimprimir Recibo',
          DETAIL: 'Contigências',
          WISHLIST: 'Fluxo de Venda - Todas as Agencias',
          CHECKOUT: 'Fluxo de Venda por Atrativo'
        },
        USER: {
          COLLAPSIBLE: 'Configurações',
          LIST: 'Prefeitura',
          VIEW: 'Mudar Senha',
          EDIT: 'Parametros',
          GERAIS: 'Gerais'
        }
      },
      PAGES: {
        SECTION: 'Cadastro',
        AUTH: {
          COLLAPSIBLE: 'Usuários',
          LOGIN1: 'Login V1',
          LOGIN2: 'Login V2',
          REGISTER1: 'Register V1',
          REGISTER2: 'Register V2',
          FORGOTPASSWORD1: 'Forgot Password V1',
          FORGOTPASSWORD2: 'Forgot Password V2',
          RESETPASSWORD1: 'Mudar senha',
          RESETPASSWORD2: 'Reset Password V2'
        },
        ACCOUNTSETTINGS: 'Usuários',
        ROLES: 'Roles',
        PREFEITURA: 'Prefeitura',
        EMPRESA: 'Empresa',
        PERFIL: 'Perfil',
        CLIENTE: 'Cliente',
        PROFILE: 'Empresas',
        //FAQ: 'Clientes',
        KB: 'Clientes - Simplificado',
        PRICING: 'Veiculos / Embarcação',
        GC: 'Guia / Condutor',
        CP: 'Passeios',
        MP: 'Motorista / Piloto',
        TRIP: 'Tripulantes',
        MORAD: 'Moradores',
        TEMP: 'Temporada',
        HORARIO: 'Horarios',
        DISP: 'Consultar Disponibilidade de vagas',
        HOSP: 'Hospedagem',
        // BLOG: {
        //   COLLAPSIBLE: 'Blog',
        //   LIST: 'List',
        //   DETAILS: 'Details',
        //   EDIT: 'Edit'
        },
      //   MAIL: {
      //     COLLAPSIBLE: 'Mail Template',
      //     WELCOME: 'Welcome',
      //     RESET: 'Reset Password',
      //     VERIFY: 'Verify',
      //     DEACTIVATE: 'Deactivate',
      //     INVOICE: 'Invoice',
      //     PROMOTIONAL: 'Promotional'
      //   },
      //   MISC: {
      //     COLLAPSIBLE: 'Miscellaneous',
      //     COMINGSOON: 'Coming Soon',
      //     NOTAUTH: 'Not Authorized',
      //     MAINTENANCE: 'Maintenance',
      //     ERROR: 'Error'
      //   }
      // },
      UI: {
        SECTION: 'User Interface',
        TYPOGRAPHY: 'Typography',
        COLORS: 'Colors',
        FEATHER: 'Feather',
        CARDS: {
          COLLAPSIBLE: 'Relatórios',
          BADGE: '',
          BASIC: 'Por Empresas',
       //ADVANCE: 'Por Listas',

       LISTA: {
        COLLAPSIBLE: 'Por Listas',
        ATRATIVO: 'Lista de Atrativos',
        MOTORISTAS: 'Lista de Motoristas / Pilotos',
        VEICULOS: 'Lista de Veiculos',
        GUIAS: 'Lista de Guias',
        EMPRESAS: 'Lista de Empresas'
      },

          STATISTICS: 'Fluxo de Venda',
          ANALYTICS: 'Capacidade de Carga',
          ACTIONS: 'Lista de Portais',
          vouche: 'Reimprimir Voucher',
          Fluxoatrativo: 'Vendas por Atrativo',
          guias: 'Fluxo de Guias',
          motoristas: 'Fluxo de Motoristas',
          hospedagem: 'Fluxo de Hospedagem',
          transito: 'Transito',
          visitantes: 'Visitantes',
          fluxogerencial: 'Fluxo Gerencial',
          agendamentos: 'Agendamentos',
          compartilhamento: 'Agencias' 
        },
        COMPONENTS: {
          COLLAPSIBLE: 'Components',
          ALERTS: 'Alerts',
          AVATAR: 'Avatar',
          BADGES: 'Badges',
          BREADCRUMBS: 'Breadcrumbs',
          BUTTONS: 'Buttons',
          CAROUSEL: 'Carousel',
          COLLAPSE: 'Collapse',
          DIVIDER: 'Divider',
          DROPDOWNS: 'Dropdowns',
          GROUP: 'List Group',
          OBJECTS: 'Media Objects',
          MODALS: 'Modals',
          COMPONENT: 'Navs',
          PAGINATION: 'Pagination',
          PBADGES: 'Pill Badges',
          PILLS: 'Pills',
          POPOVERS: 'Popovers',
          PROGRESS: 'Progress',
          RATINGS: 'Ratings',
          SPINNER: 'Spinner',
          TABS: 'Tabs',
          TIMELINE: 'Timeline',
          TOASTS: 'Toasts',
          TOOLTIPS: 'Tooltips'
        },
        EX: {
          COLLAPSIBLE: 'Extension',
          SWEET_ALERTS: 'Sweet Alerts',
          BLOCKUI: 'BlockUI',
          TOASTER: 'Toastr',
          SLIDER: 'Slider',
          DRAGDROP: 'Drag & Drop',
          TOUR: 'Tour',
          CLIPBOARD: 'Clipboard',
          MEDIAPLAYER: 'Media Player',
          CONTEXTMENU: 'Context Menu',
          SWIPER: 'Swiper',
          TREEVIEW: 'Tree View',
          I18N: 'I18n'
        },
        LAYOUTS: {
          COLLAPSIBLE: 'Page Layouts',
          COLLAPSED_MENU: 'Collapsed Menu',
          BOXED_LAYOUT: 'Boxed Layout',
          WITHOUT_MENU: 'Without Menu',
          LAYOUT_EMPTY: 'Layout Empty',
          LAYOUT_BLANK: 'Layout Blank'
        }
      },
      FT: {
        SECTION: 'Forms & Tables',
        ELEMENT: {
          COLLAPSIBLE: 'Form Elements',
          INPUT: 'Input',
          INPUTGROUPS: 'Input Groups',
          INPUTMASK: 'Input Mask',
          TEXTAREA: 'Textarea',
          CHECKBOX: 'Checkbox',
          RADIO: 'Radio',
          SWITCH: 'Switch',
          SELECT: 'Select',
          NUMBERINPUT: 'Number Input',
          FILEUPLOADER: 'File Uploader',
          QUILLEDITOR: 'Quill Editor',
          FLATPICKER: 'Flatpicker',
          DATETIMEPICKER: 'Date & Time Picker'
        },
        LAYOUTS: 'Form Layouts',
        WIZARD: 'Form Wizard',
        VALIDATION: 'Form Validations',
        REPEATER: 'Form Repeater',
        TABLE: 'Table',
        DATATABLES: 'DataTables'
      },
      CM: {
        SECTION: 'Charts & Maps',
        CHARTS: {
          COLLAPSIBLE: 'Charts',
          BADGE: '2',
          APEX: 'Apex',
          CHARTJS: 'ChartJS'
        },
        MAPS: 'Google Maps'
      },
      OTHERS: {
        SECTION: 'Others',
        LEVELS: {
          COLLAPSIBLE: 'Menu Levels',
          SECOND: 'Second Level',
          SECOND1: {
            COLLAPSIBLE: 'Second Level',
            THIRD: 'Third Level',
            THIRD1: 'Third Level'
          }
        },
        DISABLED: 'Disabled Menu',
        DOCUMENTATION: 'Documentation',
        SUPPORT: 'Raise Support'
      }
    }
  }
};