<span class="footer footer-fixed-login">
  <div class="row"> 
    <div class="col-xl-3 col-md-1 col-3">
      <label for="disabledInput"><b>Versão</b>: {{ version }}</label>
    </div>   
    <div class="col-xl-3 col-md-3 col-3">      
        <label for="disabledInput"><b>Perfil</b>: {{currentUser?.user?.perfilObj?.nome ?? currentUser?.user?.nomePerfil}}</label>          
    </div>
    <div class="col-xl-3 col-md-4 col-3" *ngIf="currentUser?.user?.nomeEmpresa || currentUser?.user?.empresa?.nomeFantasia">
        <label for="disabledInput"><b>Empresa</b>: {{ currentUser?.user?.empresa?.nomeFantasia ?? currentUser?.user?.nomeEmpresa }}</label>
    </div>
    
    <div class="col-xl-3 col-md-4 col-3"><!--?? currentUser?.user?.nomePrefeitura-->
        <label for="disabledInput"><b>Contexto</b>: {{currentUser?.user?.prefeitura?.nomeFantasia }}</label>
    </div>
  </div>
</span>
