import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDatepickerI18nDefault, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18nDefault {
  private weekdaysShort = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  private monthsShort = [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
  ];
  private monthsFull = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ];

  getWeekdayShortName(weekday: number): string {
    return this.weekdaysShort[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return this.monthsShort[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.monthsFull[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
