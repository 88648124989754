import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Prefeitura, TipoUsuario } from 'app/auth/models';

@Injectable({ providedIn: 'root' })
export class PrefeituraService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get all users
   */
  getAll(options: { noPage?: boolean; filtro?:string } = {}) {
    const { noPage = false,  filtro} = options;
    let URL = `${environment.apiUrl}${environment.cadastro}/prefeituras`
    let filtrosUrl: string[] = [];

    if (noPage) {
      filtrosUrl.push("noPage=true");
    }

    /*if(tipoUsuario === TipoUsuario.ADMINISTRADOR){
      filtrosUrl.push("prefeituraId=null", "empresaId=null");
    }
   

    if (prefeituraId && prefeituraId.trim() !== '') {
      filtrosUrl.push(`prefeituraId=${prefeituraId}`);
    }

    if (empresaId && empresaId.trim() !== '') {
      filtrosUrl.push(`empresaId=${empresaId}`);
    }*/

    if (filtro && filtro.trim() !== '') {
      filtrosUrl.push(`razaoSocial=${filtro}`, `nomeFantasia=${filtro}`, `cnpj=${filtro}`);
    }
  
    // Constrói a URL com base nos filtros adicionados
    if (filtrosUrl.length > 0) {
      // Começa com ? e adiciona os filtros com &
      URL += '?' + filtrosUrl.join('&');
    }

    return this._http.get<Prefeitura[]>(URL);
  }

  getById(prefeituraId: string) {
    let URL = `${environment.apiUrl}${environment.cadastro}/prefeituras`
    return this._http.get<Prefeitura>(`${URL}/${prefeituraId}`);
  }
  
}
