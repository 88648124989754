import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cep'
})
export class CepPipe implements PipeTransform {

  transform(value: string | number): string {
    if (!value) return '';
    
    const rawValue = value.toString().replace(/\D/g, ''); // Remove caracteres não numéricos

    if (rawValue.length === 8) {
      return `${rawValue.substring(0, 2)}.${rawValue.substring(2, 5)}-${rawValue.substring(5)}`;
    }

    return value.toString(); // Retorna o valor original caso não tenha 8 dígitos
  }

}