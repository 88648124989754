import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cnpjCpf'
})
export class CnpjCpfPipe implements PipeTransform {


  // transform(value: string | number): string {
  //   if (!value) return '';
  
  //   const numericValue = value.toString().replace(/\D/g, '');
  
  //   if (numericValue.length === 11) {
  //     // Formatar como CPF
  //     return numericValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
  //   }
  
  //   if (numericValue.length === 14) {
  //     // Formatar como CNPJ
  //     return numericValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  //   }
  
  //   // Retornar o valor original se não for CPF ou CNPJ válido
  //   return value.toString();
  // }

  transform(value: string | number, ocultar: boolean = false): string {
    if (!value) return '';
  
    const numericValue = value.toString().replace(/\D/g, '');
  
    if (numericValue.length === 11) {
      if (ocultar) {
        // Formatar CPF com máscara de ocultação
        let somenteMun: string  = numericValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');

        let stringRetur = somenteMun[0]+somenteMun[1]+"*.***.***-"+somenteMun[12]+somenteMun[13];

        
        return stringRetur;
        
      }
      // Formatar CPF sem máscara de ocultação
      return numericValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    }
  
    if (numericValue.length === 14) {
      // Formatar como CNPJ
      return numericValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    }
  
    // Retornar o valor original se não for CPF ou CNPJ válido
    return value.toString();
  }

}
