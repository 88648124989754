import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';
import { CnpjCpfPipe } from '@core/pipes/cnpj-cpf.pipe';
import { FormatDatePipe } from '@core/pipes/format-date.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { TelefonePipe } from './telefone.pipe';
import { ParseDatePipe } from './parse-date.pipe';
import { CepPipe } from './cep.pipe';
import { MoedaPipe } from './moeda.pipe';
import { NumeroPipe } from './numero.pipe';


@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe,CnpjCpfPipe, TelefonePipe, FormatDatePipe, ParseDatePipe, CepPipe, MoedaPipe, NumeroPipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe,CnpjCpfPipe, TelefonePipe, FormatDatePipe, ParseDatePipe, CepPipe, MoedaPipe, NumeroPipe]
})
export class CorePipesModule {}
