import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'numero'
})
export class NumeroPipe implements PipeTransform {

  transform(value: string | number): string {
    if(value && value != null){
     const numValue: number = Number(value);
     let valosString = numValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
     return  valosString.replace("R$", "");
    }else{
      return "-";
    }
  }

}